import React from "react";
import PropTypes from "prop-types";
import ProgressBar from "react-bootstrap/ProgressBar";
import { WIDGET_TYPE_PROGRESS, WIDGET_TYPE_POSTER, WIDGET_ACTION_PLAY, WIDGET_TYPE_LIVE_V2 } from "../../contanst";
import defaultBanner from "@app/Resources/Images/default_banner.png";
import iconTVG from "@app/Resources/Images/icono_TVG.png";
import style from "./style.module.css";
import styleHover from "../PlaylistItem/style.module.css";

import { URL_LOGIN } from "../../../Routes/Location";
import { useIsLogin } from "../../utils/utils";
import { actionTypeContainer } from "../../../../Utils/utils";
import { Link } from "react-router-dom";
import ActionButtons from "../ActionButtons/ActionButtons";
import VideoType from "../../../../../../detail/src/Pages/DetailPage/VideoType";
import moment from "moment";
import { useSelector } from "react-redux";

function MediaCover({ data, typeBanner, event, access }) {
  const { id, title, video, tags, progress } = data;
  const isLogin = useIsLogin();
  const isTVG = tags?.size !== 0 && typeof tags === 'string' && tags?.toArray().includes("TVG");
  const tenantIsFreeToPlay = useSelector((state) => state.commonState.tenantStyles.isFreeToPlay);

  const isOnAir = () => {
    return moment(new Date(data.startTimeMs?.toNumber())).format() < moment(new Date()).format();
  }
  const getImage = (tenantIsFreeToPlay) => {
    const banner = data.banner?.url || data.poster?.url;

    return (
      <Link to={getLinkProps(tenantIsFreeToPlay)}>
        <img
          className={`${style.playlistItemImage} ${styleHover.playlistItemImage} ${typeBanner === WIDGET_TYPE_POSTER && style.playlistItemImage__vertical
            }`}
          src={banner || defaultBanner}
          alt={title}
        />
        {typeBanner === WIDGET_TYPE_PROGRESS && (
          <ProgressBar now={progress} className={style.barProgress} />
        )}
      </Link>
    );
  };

  const getLinkProps = (tenantIsFreeToPlay) => {
    const url = video?.url;
    const type = video?.type;
    const accessResult = actionTypeContainer(access).ROUTE + "/" + id;

    return {
      pathname: !!tenantIsFreeToPlay && !isLogin && accessResult === WIDGET_ACTION_PLAY ? URL_LOGIN : accessResult,
      state: { url, type, id },
    };
  };

  return (
    <div className={`${style.playlistHeader} ${styleHover.playlistHeader}`}>
      {getImage(tenantIsFreeToPlay)}
      {isTVG && <img className={style.iconTVG} src={iconTVG} alt={"iconTVG"} />}
      {actionTypeContainer(access).SHOW_BUTTONS &&
      <ActionButtons data={data} access={access} event={event} /> }
      {typeBanner === WIDGET_TYPE_LIVE_V2 && <VideoType subtitle={data} isLiveWidget onAir={isOnAir()} />}
    </div>
  );
}
export default MediaCover;
MediaCover.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    tags: PropTypes.object,
  }).isRequired,
  typeBanner: PropTypes.string,
  access: PropTypes.string.isRequired,
};
