import React from "react";

import PetisgoLoader from "./petisgo/PetisgoLoader";
import AndromedaLoader from "./andromeda/AndromedaLoader";

import defaultFavicon from "./default/Resources/favicon.ico";
import petisgoFavicon from "./petisgo/Resources/favicon.png";
import andromedaFavicon from "./andromeda/Resources/favicon.png";
import casiopeaFavicon from "./casiopea/Resources/favicon.ico";
import tiiviiFavicon from "./tiivii/Resources/favicon.ico";
import bolerusFavicon from "./bolerus/Resources/favicon.ico";
import dracoFavicon from "./draco/Resources/favicon.ico";
import eudoraFavicon from "./eudora/Resources/favicon.ico";
import geminiFavicon from "./gemini/Resources/favicon.ico";
import felisFavicon from "./felis/Resources/favicon.ico";


import petisgoBackground from "./petisgo/Resources/background_register.jpg";
import andromedaBackground from "./andromeda/Resources/background_register.jpg";
import defaultBackground from "./default/Resources/background_register.jpg";
import tiiviitBackground from "./tiivii/Resources/background_register.jpg";
import CircularProgress from "@material-ui/core/CircularProgress";
import bolerusBackground from "./bolerus/Resources/background_register.jpg";
import dracoBackground from "./draco/Resources/background_register.png";
import eudoraBackground from "./eudora/Resources/background_register.jpg";
import casiopeaBackground from "./casiopea/Resources/background_register.jpg"
import geminiBackground from "./gemini/Resources/fondo-login-dtm.png";
import felisBackground from "./felis/Resources/fondo-login-dtm.JPG";

import { coreBuild } from "../../../core-framework";
import {
  DEFAULT_SIMPLE_BIRTH_DATE,
  SIGNUP_MODE_BY_AGE,
  SIGNUP_MODE_SIMPLE,
} from "../../../../user/src/Pages/Register/RegisterConstant";

//Necesario ya que el core ahora está devolviendo sólo el cumpleaños.
const registerMode = () => {
  if (!!coreBuild.coreConfig.signup.mode.id) {
    return coreBuild.coreConfig.signup.mode.id;
  } else {
    return coreBuild.coreConfig.signup.mode.defaultBirthday === DEFAULT_SIMPLE_BIRTH_DATE
      ? SIGNUP_MODE_SIMPLE
      : SIGNUP_MODE_BY_AGE;
  }
};

export const SIGNUP_MODE = registerMode();

export const TENANT_TIIVII = "tiivii";
export const TENANT_TIIVII_SERVER = "ott_tiivii";
export const TENANT_ANDROMEDA = "andromeda";
export const TENANT_PETISGO = "petisgo";
export const TENANT_DEFAULT = "default";
export const TENANT_CASIOPEA = "casiopea";
export const TENANT_BOLERUS = "bolerus";
export const TENANT_DRACO = "draco";
export const TENANT_EUDORA = "eudora";
export const TENANT_GEMINI = "gemini";
export const TENANT_FELIS = "felis";


export const TENANT_REGISTRED = [
  TENANT_TIIVII,
  TENANT_ANDROMEDA,
  TENANT_PETISGO,
  TENANT_CASIOPEA,
  TENANT_BOLERUS,
  TENANT_DRACO,
  TENANT_EUDORA,
  TENANT_GEMINI,
  TENANT_FELIS
];

export const TENANT = TENANT_REGISTRED.find((item) => item === coreBuild.envConfig.tenant)
  ? coreBuild.envConfig.tenant
  : TENANT_DEFAULT;

const PETISGO = "PetisGo Creator";
const CREATOR = "Creator";
const DRACO_CREATOR = "Play Castelló";


const iconSettings = (
  <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M368 128h80M64 128h240M368 384h80M64 384h240M208 256h240M64 256h80"
    />
    <circle
      cx="336"
      cy="128"
      r="32"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <circle
      cx="176"
      cy="256"
      r="32"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <circle
      cx="336"
      cy="384"
      r="32"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
  </svg>
);

const iconLogout = (
  <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
    <path
      d="M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40M368 336l80-80-80-80M176 256h256"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
  </svg>
);
export const MULTITENANT_CONSTANTS = {
  [TENANT_PETISGO]: {
    pageTitle: "PetisGO",
    defaultLocale: "gl",
    spinner: <PetisgoLoader />,
    favicon: petisgoFavicon,
    backgroundRegister: petisgoBackground,
    momentLocale: "es",
    showVideoDetails: true,
    showVideoTags: true,
    studioSectionName: PETISGO,
    showComments: true,
    showAllIcons: true,
    showScore: true,
    showSubtitle: false,
    gamification: true,
    urlFaqs: "",
    classHeight: "",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItem",
    showSlideHighlightIndicators: true,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: true,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: true,
    showRating: true,
    showBackToSeason: true,
    showRegistrationText: true,
    showReportContentOption: true,
    showOnBoarding: true,
    showUserInterests: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_PETISGO
  },
  [TENANT_DEFAULT]: {
    pageTitle: "Tiivii",
    defaultLocale: "en",
    spinner: <CircularProgress />,
    favicon: defaultFavicon,
    backgroundRegister: defaultBackground,
    momentLocale: "es",
    showVideoDetails: true,
    showVideoTags: true,
    studioSectionName: CREATOR,
    showComments: true,
    showAllIcons: false,
    showScore: true,
    showSubtitle: false,
    gamification: true,
    urlFaqs: "",
    classHeight: "",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: true,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: true,
    showRating: true,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: true,
    showOnBoarding: true,
    showUserInterests: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_TIIVII_SERVER
  },
  [TENANT_ANDROMEDA]: {
    pageTitle: "Oh! Jazz",
    defaultLocale: "en",
    spinner: <AndromedaLoader />,
    favicon: andromedaFavicon,
    backgroundRegister: andromedaBackground,
    momentLocale: "en",
    showVideoDetails: false,
    showVideoTags: false,
    studioSectionName: PETISGO,
    showComments: false,
    showAllIcons: false,
    showScore: false,
    showSubtitle: false,
    gamification: false,
    urlFaqs: "",
    classHeight: "ImgHeight",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: true,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    configurationSectionImage: iconSettings,
    logoutSectionImage: iconLogout,
    menuClassLogout: "",
    showAuthor: false,
    showCategory: false,
    showLoginButtonInMobile: false,
    showPublishDate: false,
    showRating: false,
    showBackToSeason: false,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: false,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_ANDROMEDA
  },
  [TENANT_TIIVII]: {
    pageTitle: "Tiivii Sports",
    defaultLocale: "en",
    spinner: <CircularProgress />,
    favicon: tiiviiFavicon,
    backgroundRegister: tiiviitBackground,
    momentLocale: "es",
    showVideoDetails: true,
    showVideoTags: true,
    studioSectionName: CREATOR,
    showComments: true,
    showAllIcons: false,
    showScore: true,
    showSubtitle: false,
    gamification: true,
    urlFaqs: "https://landings.tiivii.com/tiiviifaqs/",
    classHeight: "",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: true,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: true,
    showRating: true,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: true,
    showOnBoarding: false,
    showUserInterests: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_TIIVII_SERVER
  },
  [TENANT_CASIOPEA]: {
    pageTitle: "Teatre Principal - Teatro",
    defaultLocale: "ca",
    spinner: <CircularProgress />,
    favicon: casiopeaFavicon,
    backgroundRegister: casiopeaBackground,
    momentLocale: "es",
    showVideoDetails: true,
    showVideoTags: true,
    studioSectionName: CREATOR,
    showComments: false,
    showAllIcons: false,
    showScore: false,
    showSubtitle: true,
    gamification: false,
    urlFaqs: "",
    classHeight: "ImgHeight",
    bannerHighlightedHeightClassName: "PlaylistHighlightBig",
    showOnlySons: true,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: false,
    showLoginButtonInMobile: true,
    showPublishDate: false,
    showRating: false,
    showBackToSeason: false,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_CASIOPEA
  },
  [TENANT_BOLERUS]: {
    pageTitle: "Lalin Infinito OTT",
    defaultLocale: "gl",
    spinner: <CircularProgress />,
    favicon: bolerusFavicon,
    backgroundRegister: bolerusBackground,
    momentLocale: "es",
    showVideoDetails: true,
    showVideoTags: true,
    studioSectionName: CREATOR,
    showComments: true,
    showAllIcons: false,
    showScore: false,
    showSubtitle: false,
    gamification: false,
    urlFaqs: "",
    classHeight: "",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    configurationSectionImage: iconSettings,
    logoutSectionImage: iconLogout,
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: true,
    showRating: false,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_BOLERUS
  },
  [TENANT_DRACO]: {
    pageTitle: "Play Castelló",
    defaultLocale: "vc",
    spinner: <CircularProgress />,
    favicon: dracoFavicon,
    backgroundRegister: dracoBackground,
    momentLocale: "es",
    showVideoDetails: false,
    showVideoTags: true,
    studioSectionName: DRACO_CREATOR,
    showComments: false,
    showAllIcons: true,
    showScore: false,
    showSubtitle: false,
    gamification: false,
    urlFaqs: "",
    classHeight: "",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItem",
    showSlideHighlightIndicators: true,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: false,
    showRating: false,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_DRACO
  },
  [TENANT_EUDORA]: {
    pageTitle: "Arteixo Conecta",
    defaultLocale: "gl",
    spinner: <CircularProgress />,
    favicon: eudoraFavicon,
    backgroundRegister: eudoraBackground,
    momentLocale: "es",
    showVideoDetails: true,
    showVideoTags: true,
    studioSectionName: CREATOR,
    showComments: true,
    showAllIcons: false,
    showScore: false,
    showSubtitle: false,
    gamification: false,
    urlFaqs: "",
    classHeight: "",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    configurationSectionImage: iconSettings,
    logoutSectionImage: iconLogout,
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: true,
    showRating: false,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_EUDORA
  },
  [TENANT_GEMINI]: {
    pageTitle: "Da Túa Man",
    defaultLocale: "gl",
    spinner: <CircularProgress />,
    favicon: geminiFavicon,
    backgroundRegister: geminiBackground,
    momentLocale: "es",
    showVideoDetails: false,
    showVideoTags: true,
    studioSectionName: CREATOR,
    showComments: true,
    showAllIcons: true,
    showScore: false,
    showSubtitle: false,
    gamification: false,
    urlFaqs: "https://landings.tiivii.com/geminifaqs/",
    classHeight: "",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItem",
    showSlideHighlightIndicators: true,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: false,
    showRating: false,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_GEMINI
  },
  [TENANT_FELIS]:
      {
        pageTitle: "Redondela Aberta",
        defaultLocale: "gl",
        spinner: <CircularProgress />,
        favicon: felisFavicon,
        backgroundRegister: felisBackground,
        momentLocale: "es",
        showVideoDetails: true,
        showVideoTags: true,
        studioSectionName: CREATOR,
        showComments: false,
        showAllIcons: false,
        showScore: false,
        showSubtitle: false,
        gamification: false,
        urlFaqs: "",
        classHeight: "",
        bannerHighlightedHeightClassName: "PlaylistHighlight",
        showOnlySons: false,
        classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
        showSlideHighlightIndicators: true,
        configurationSectionImage: iconSettings,
        logoutSectionImage: iconLogout,
        menuClassLogout: "LogoutIcon",
        showAuthor: false,
        showCategory: true,
        showLoginButtonInMobile: true,
        showPublishDate: false,
        showRating: false,
        showBackToSeason: true,
        showRegistrationText: false,
        showReportContentOption: false,
        showOnBoarding: false,
        showUserInterests: true,
        /*cookies: se meten desde el index.html*/
        sticky: true,
        tenantServer: TENANT_FELIS
      }
};
