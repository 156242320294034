export const URL_BASE = "https://streamer-cdn.ott.tiivii.com/v1/";
export const URL_SGA = "sgca/";
export const URL_SERGIO = "sergio/";
export const URL_IDEN = "iden/";
export const URL_ANALYTICS = "analytics/"
export const URL_CAS = "cas/";
export const URL_REM = "reme/";



