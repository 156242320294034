import React, {useEffect, useState} from "react";
import {
    TRANSITION_DURATION,
    TYPE_BANNER_BANNER,
    TYPE_BANNER_BANNER_LARGE,
    TYPE_BANNER_POSTER,
    TYPE_BANNER_PROGRESS,
} from "../PlaylistConstants";
import {Link} from "react-router-dom";
import Carousel from "react-multi-carousel";
import ProgressBar from 'react-bootstrap/ProgressBar'
import Favorite from "@detail/Pages/DetailPage/Favorite";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {URL_CONTENT} from "@components/Routes/Location";
import {responsive, responsivelarge} from '@detail/Pages/DetailPage/CarrouselStyle';
import defaultBanner from '@app/Resources/Images/default_banner.png';
import defaultBannerLarge from '@app/Resources/Images/default_banner_LARGE.jpg';
import iconTVG from '@app/Resources/Images/icono_TVG.png';
import {useTranslation} from 'react-i18next';
import {NotificationContainer} from 'react-notifications';
import "react-multi-carousel/lib/styles.css";
import '@detail/Pages/DetailPage/CarrouselStyle.css';
import {DATA_LOADING_STATUS_LOADED, TVG, WIDGET_ACTION_PLAY} from "../../../../../app/src/Utils/Constants";
import {evaluateItemClass, LIMIT_ITEMS} from "./utils";
import VideoType from "../VideoType";
import Spinner from 'react-bootstrap/Spinner';
import {actionTypeContainer} from "../../../../../app/src/Utils/utils";
import {useKeycloak} from "@react-keycloak/web";
import {URL_LOGIN} from "../../../../../app/src/Components/Routes/Location";
import VideoRating from "../VideoRating";
import {MULTITENANT_CONSTANTS, TENANT} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import {ORIGIN_SECTIONS} from "../../../../../app/src/Components/widget_v2/contanst";
import {useSelector} from "react-redux";

const DefaultWidgetComponent = ({
                                    key, className, style, titulo, hasMoreContents, id,
                                    typeBanner, playlist, playSpinner, clickCallback,
                                    controller, event, turnOnLoading, access,
                                    titleColor, contentId,
                                    statusLoader, pageLocation, origin
                                }) => {

    const {t} = useTranslation();

    const [limitNumberCards, setLimitNumberCards] = useState(LIMIT_ITEMS);
    const [overOnCard, setOverOnCard] = useState(false);
    const tenantIsFreeToPlay = useSelector((state) => state.commonState.tenantStyles.isFreeToPlay);

    const minResolutionToCarousel = 14000;

    useEffect(() => {
        if (window.innerWidth < minResolutionToCarousel) {
            setLimitNumberCards(3);
        }
    }, [playlist]);


    const minimumItems = +playlist?.length < limitNumberCards ? " BlockCarousel" : " ";

    const createImage = (image, title) => {
        return <img className="PlaylistItemImage"
                    src={image}
                    alt={title}/>
    }

    const getImageDefault = (typeBanner) => {
        switch (typeBanner.toUpperCase()) {
            case TYPE_BANNER_POSTER:
                return defaultBannerLarge
            default:
                return defaultBanner
        }
    }

    const getImage = (typeBanner, w) => {
        switch (typeBanner.toUpperCase()) {
            case TYPE_BANNER_POSTER:
                return createImage(!!w.poster ? w.poster?.url
                    : !!w.banner && w.banner?.url, w.title)
            case TYPE_BANNER_BANNER:
                return createImage(!!w.banner ? w.banner?.url
                    : !!w.poster && w.poster?.url, w.title)
            case TYPE_BANNER_BANNER_LARGE:
                return createImage(!!w.background ? w.background?.url
                    : !!w.banner && w.banner?.url, w.title)
            case TYPE_BANNER_PROGRESS:
                return <> {createImage(!!w.banner ? w.banner?.url
                    : !!w.poster && w.poster?.url, w.title)}
                    <div style={{marginTop: "-11px"}}>
                        <ProgressBar now={w.progress} className="BarProgress"/>
                    </div>
                </>
            default:
                return <img className="PlaylistItemImage" src={defaultBanner} alt={w.title}/>
        }

    }

    const { keycloak, initialized } = useKeycloak();

    const isLogin = () => {
        return initialized && keycloak.authenticated;
    }

    return (<>
        {origin === "ORIGIN_SECTIONS" && <div className='SpaceOriginSection'></div>}
        <div className="PlaylistContainer" key={key}>
        <div className="CarouselContainer">
            <div className={"Playlist " + className} style={style}>
                <div className={"Titulo"} style={{display: "flex"}}>
                    <p style={{width: "auto", color: titleColor}}>{statusLoader === DATA_LOADING_STATUS_LOADED && titulo}</p>
                    {hasMoreContents &&
                    <Link to={URL_CONTENT + "/widget/" + id}
                          style={{padding: "0 0 0 15px", display: "flex", width: "auto"}}
                          className="Titulo button-link-secondary">
                        <div className="ShowMoreText">{t('ficha.showmore')}</div>
                        <FontAwesomeIcon icon={faChevronRight} style={{margin: "10px 0 0 5px"}}/>
                    </Link>}
                </div>
                { playlist?.length > 0 &&
                <Carousel
                    swipeable={true}
                    draggable
                    showDots={false}
                    responsive={typeBanner.toUpperCase() === TYPE_BANNER_BANNER_LARGE ? responsivelarge : responsive}
                    infinite={true}
                    autoPlay={false}
                    keyBoardControl={false}
                    transitionDuration={TRANSITION_DURATION}
                    customTransition="transform 1000ms ease-in-out 0s"
                    centerMode={false}
                    partialVisible={true}
                    containerClass={"carousel-container "  + typeBanner  + ' ' + minimumItems + " PlaylistCollection"}
                    dotListClass="custom-dot-list-style"
                    itemClass={"CardContainer"}
                >
                    {playlist?.map((w, i) => {
                            return <div className={evaluateItemClass({
                                i, overOnCard,
                                playlist, itemClass: 'PlaylistItemContainer'
                            })} key={w.id + "-" + w.video?.url}
                                        onMouseEnter={() => setOverOnCard(i)}
                                        onMouseLeave={() => setOverOnCard(false)}
                            >
                                <Link to={{
                                    pathname: !tenantIsFreeToPlay && !isLogin() && access === WIDGET_ACTION_PLAY ?
                                        URL_LOGIN : actionTypeContainer(access).ROUTE + "/" + w.id,
                                    state: {
                                        url: w.video?.url,
                                        type: w.video?.type,
                                        id: w.id
                                    }
                                }} onClick={() => contentId !== w.id && turnOnLoading()}>

                                    <div
                                        className="PlaylistItem"
                                        onClick={clickCallback ? () => clickCallback(w.id) : ''}
                                        key={w.titulo + "-" + w.video?.url}
                                    >
                                            {typeBanner && (w.background?.url || w.poster?.url) ?
                                                getImage(typeBanner, w)
                                            : (w.background?.url === null && w.poster?.url === null) ||
                                            (!w.background?.url && !w.poster?.url) &&
                                                <img className="PlaylistItemImage"
                                                     src={getImageDefault(typeBanner)}
                                                     alt={w.title}/>
                                            }
                                            {w.tags?.size !== 0 && typeof w.tags === 'string' && w.tags?.toArray().includes(TVG) &&
                                                <img className="iconTVG" src={iconTVG} alt={"iconTVG"}/>
                                            }
                                        <VideoType subtitle={w}/>
                                    </div>
                                </Link>
                                <div className="PlaylistItemFooter">
                                    <Link to={{
                                        pathname: !tenantIsFreeToPlay && !isLogin() && access === WIDGET_ACTION_PLAY ?
                                            URL_LOGIN : actionTypeContainer(access).ROUTE + "/" + w.id,
                                        state: {
                                            url: w.video?.url,
                                            type: w.video?.type,
                                            id: w.id
                                        }
                                    }} onClick={() => contentId !== w.id && turnOnLoading()}>
                                    <div className="PlaylistItemTitle">{w.title}</div>

                                    <div className={"ListIcons"}>
                                        {MULTITENANT_CONSTANTS[TENANT].showRating ? w?.score !== undefined && <>
                                                <VideoRating w={w}/>
                                        </> :
                                            MULTITENANT_CONSTANTS[TENANT].showSubtitle &&
                                            <div className="PlaylistItemSubtitlePerRating">{w.subtitle}</div>
                                        }
                                        <div className="PlaylistItemGenre">
                                            {w.category &&
                                            <span className="Xenero">
                                                    {w.category}
                                                </span>
                                            }
                                            {w.seasons &&
                                            <span className="Temporadas">
                                                    {t('ficha.seasons', {seasons: w.seasons})}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    </Link>
                                    <div className="PlayFavContainer">
                                        <Link className="linkSpace" to={{
                                            pathname: !tenantIsFreeToPlay && !isLogin() && access === WIDGET_ACTION_PLAY ?
                                                URL_LOGIN : actionTypeContainer(access).ROUTE + "/" + w.id,
                                            state: {
                                                url: w.video?.url,
                                                type: w.video?.type,
                                                id: w.id
                                            }
                                        }}>
                                        <div className="boton-play BotonVer button-focused">
                                            {
                                                !tenantIsFreeToPlay && !isLogin() && access === WIDGET_ACTION_PLAY ?
                                                    t('ficha.login') : t('ficha.' + actionTypeContainer(access).TEXT)
                                            }
                                        </div>
                                        </Link>
                                        {w.canFav &&
                                        <Favorite controller={controller} events={event} id={w.id}
                                                  isFavorite={w.isFav} isPlaylist={true}/>}
                                    </div>
                                </div>
                            </div>
                        }
                    )
                    }
                </Carousel>}
            </div>
            <div className={"Alert"}>
                <NotificationContainer/>
            </div>
            {
                playSpinner && <div className="SpinnerContainer">
                    <Spinner animation="border" variant="primary"/>
                </div>
            }
        </div>
    </div></>)
};

export default DefaultWidgetComponent;