import React, {useEffect, useState} from 'react';
import {Col, Row} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import {user} from '@core';
import {deleteUserInterestsService, updateUserInterestsService} from "../../../../../app/src/services/services";
import {TENANT} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import {useKeycloak} from "@react-keycloak/web";
import {saveUserDataActionCreator} from "../../../../../app/src/actions/commonActions";
import {useDispatch} from "react-redux";

let catIni = null;

const SelectCategoriesComponent = (props) => {

    const {userProfile, userAppController} = props;
    const [controller, setController] = useState(null);
    const [categorias, setCategorias] = useState([]);
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [categoriasUnselect, setCategoriasUnselect] = useState([])
    const [advise, setAdvise] = useState(false);

    const dispatch = useDispatch();

    const { keycloak } = useKeycloak();
    const userToken = keycloak?.token

    const {t} = useTranslation();

    const con = userAppController;

useEffect(() => {
    let index ='';
    let catsUnselect = []
    categorias.map ((categoryToSelect) => {
        index = categoriesSelected?.length > 0 &&
            categoriesSelected.map((categorySelected) => categorySelected.name).indexOf(categoryToSelect.name) !== -1;
        if (!index) {
            catsUnselect.push(categoryToSelect);
        }
    })
    setCategoriasUnselect(catsUnselect);
}, [categoriesSelected, categorias]);


const filterCategoriesById = () => {
        return categorias.filter(categoria => userProfile?.interests.includes(categoria.id));
    }

useEffect(() => {
    if (categoriesSelected?.length === 0) {
        setCategoriesSelected(filterCategoriesById);
    }
},[userProfile,categorias])

useEffect(() => {
    let binding = con.bind(model, output);

    const evas = user.events.GetAvailableInterests;
    con.dispatch(evas)
    setController(con)

    catIni = null;

    return () => {
        con.unbind(binding);
    }
}, []);

const model = (o) => {
    if (o.availableInterests) {
        let as = [];
        let it = o.availableInterests.iterator();
        while (it.hasNext()) {
            let a = it.next();
            as.push(a)
        }
        setCategorias(as);
    }
    if (o.interests) {
        let as = [];
        let it = o.interests.iterator();
        while (it.hasNext()) {
            let a = it.next();
            as.push(a)
        }
    }
}

const output = () => {}

const updateUserInterestsCallback = (response) => {
    dispatch(saveUserDataActionCreator(response))
  }

const extractIds = (data) => {
    return data.map(item => {
        if (item && typeof item === 'object' && 'id' in item) {
            return item.id;
        }
        return undefined;
    }).filter(id => id !== undefined);
}

const deleteInterest = (categorySelected) => {
    const interestsToDelete = extractIds([categorySelected]);
    deleteUserInterestsService(TENANT, userProfile.username, interestsToDelete, userToken, updateUserInterestsCallback)
}

const updateInterests = (categoriesUser) => {
    setCategoriesSelected(categoriesUser);
    const payload = {
        "interests" : extractIds(categoriesUser)
    }
    updateUserInterestsService(TENANT, userProfile.username, payload, userToken, updateUserInterestsCallback)
}

    const updateCategories = (categorySelected, deleteCategory) => {
        setAdvise(false);
        let categoriesUser = !!categoriesSelected?.length ? [...categoriesSelected] : [];
        let index = categoriesUser.findIndex(category => category.name === categorySelected.name);

        if (deleteCategory && index !== -1) {
            categoriesUser.splice(index, 1);
        } else if (!deleteCategory && index === -1) {
            categoriesUser.push(categorySelected);
        }

        if (categoriesUser.length > 0) {
            setCategoriesSelected(categoriesUser);
            if (deleteCategory) {
                deleteInterest(categorySelected);
            } else {
                updateInterests(categoriesUser);
            }
        } else {
            setAdvise(true);
        }
    }


return (<div className="Intereses-usuario">
    <h2 className={"Title-bold"}>{t('perfil.intereses')}</h2>
    <Row>
        <Col xs="12" className={'ProfileInterestsList ProfileSelectedInterestsList'}>
            {!!categoriesSelected && categoriesSelected?.map((category) =>
                <div style={{backgroundImage: "url(" + category?.image?.url + ")"}}
                     className="Interes" onClick={()=> updateCategories(category, true)} key={category.id}>{category.name}</div>
            )}
        </Col>
        <Col xs="12" className={'ProfileInterestsList'}>
            {!!categoriasUnselect && categoriasUnselect?.map((category) =>
                <div className="Interes Unselect" onClick={()=> updateCategories(category, false)} key={category.id}>{category.name}</div>
            )}
        </Col>
    </Row>
    { !!advise && <div className={"textAlert"}>{t('register.interestAlone')}</div>}
</div>)
}

export default SelectCategoriesComponent;