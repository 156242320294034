import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import MenuLanguagesComponent from "./MenuLanguagesComponent";
import { useTranslation } from "react-i18next";
import { SHOW_LENGUAJE } from "../../../../../Utils/Constants";

export const LanguagesComponent = ({ controller, show, handelShow, renderMobileVersion }) => {
  const { t } = useTranslation();

  return (
    <div className={"languages"}>
      <OverlayTrigger
        delay={{ hide: 400, show: 120 }}
        placement="bottom"
        overlay={(props) => (
          <Tooltip {...props} className={show && "displayNoneTooltip"}>
            {t("catalogue.idioms")}
          </Tooltip>
        )}
      >
              <div onClick={() => handelShow(SHOW_LENGUAJE)} className={"LanguageMenuIconContainer"}>
                  <ion-icon name="globe-outline" id={"LanguageMenuIcon"}/>
               </div>
      </OverlayTrigger>
        <MenuLanguagesComponent
            renderMobileVersion={renderMobileVersion}
            handleShowLanguagesMenu={() => handelShow(SHOW_LENGUAJE)}
        controller={controller}
        showLanguagesMenu={show}
      />
    </div>
  );
};
