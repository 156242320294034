// KEYS
export const HTTP_REQUEST_KEY = 'request'
export const PAGE_ID_KEY = 'page'
export const NEW_LANGUAGE_KEY = 'newLanguage'
export const AVATAR_KEY = 'avatarId'
export const INTERESTS_KEY = 'interests'
export const QUERY_KEY = 'query'
export const TEXT_KEY = 'text'
export const COMMENT_KEY = 'commentId'
export const SCORE_KEY = 'rating'
export const REASON_KEY = 'reason'

// VALUES
export const HOME_VALUE = 'home'
export const SIGN_UP_VALUE = 'signUp'
export const LOGIN_VALUE = 'login'
export const SEARCH_VALUE = 'search'
export const CATALOGUE_VALUE = 'catalogue'
export const CONTENT_VALUE = 'content'
export const DETAIL_VALUE = 'detail'
export const PLAYER_VALUE = 'player'

// ACTIONS
export const API_CALL_ACTION = 'apiCall'
export const PAGE_VIEW_ACTION = 'pageView'
export const LOGIN_ACTION = 'login'

export const LOGOUT_ACTION = 'logout'
export const SIGN_UP_ACTION = 'signUp'
export const VIEW_PROFILE_ACTION = 'viewProfile'
export const CHANGE_LANGUAGE_ACTION = 'changeLanguage'
export const UPDATE_AVATAR_ACTION = 'updateAvatar'
export const UPDATE_INETERESTS_ACTION = 'updateInterests'
export const UPDATE_PASSWORD = 'updatePassword'
export const PLAY_ACTION = 'play'
export const PLAYED_LIVE_ACTION = 'playedlive'
export const RESUME_ACTION = 'resume'
export const PAUSE_ACTION = 'pause'
export const STOP = 'stop'
export const FINISHED_ACTION = 'finished'
export const SEARCH_ACTION = 'search'
export const SELECT_ITEM_ACTION = 'selectItem'
export const EXECUTE_ITEM_ACTION = 'executeItem'
export const SEND_COMMENT_ACTION = 'sendComment'
export const REMOVE_COMMENT_ACTION = 'removeComment'
export const UPDATE_COMMENT_ACTION = 'updateComment'
export const SEND_VALORATION_ACTION = 'sendValoration'
export const REMOVE_VALORATION_ACTION = 'removeValoration'
export const SET_FAVORITE_ACTION = 'setFavorite'
export const UNSET_FAVORITE_ACTION = 'unsetFavorite'
export const REPORT_CONTENT_ACTION = 'reportContent'

export const analyticsConstants = {
    [API_CALL_ACTION]: {key: HTTP_REQUEST_KEY, value: []},
    [PAGE_VIEW_ACTION]: {key: PAGE_ID_KEY,
        value: [HOME_VALUE, SIGN_UP_VALUE, LOGIN_VALUE, SEARCH_VALUE, CATALOGUE_VALUE, CONTENT_VALUE, DETAIL_VALUE, PLAYER_VALUE]},
    [LOGIN_ACTION]: {key: '', value: []},
    [LOGOUT_ACTION]: {key: '', value: []},
    [SIGN_UP_ACTION]: {key: '', value: []},
    [VIEW_PROFILE_ACTION]: {key: '', value: []},
    [CHANGE_LANGUAGE_ACTION]: {key: NEW_LANGUAGE_KEY, value: []},
    [UPDATE_AVATAR_ACTION]: {key: AVATAR_KEY, value: []},
    [UPDATE_INETERESTS_ACTION]: {key: INTERESTS_KEY, value: []},
    [UPDATE_PASSWORD]: {key: '', value: []},
    [PLAY_ACTION]: {percentage: 0, toString: function() { return "played" + this.percentage; }},
    [PLAYED_LIVE_ACTION]: {key: '', value: []},
    [RESUME_ACTION]: {key: '', value: []},
    [PAUSE_ACTION]: {key: '', value: []},
    [STOP]: {key: '', value: []},
    [FINISHED_ACTION]: {key: '', value: []},
    [SEARCH_ACTION]: {key: QUERY_KEY, value: []},
    [SELECT_ITEM_ACTION]: {key: '', value: []},
    [EXECUTE_ITEM_ACTION]: {key: '', value: []},
    [SEND_COMMENT_ACTION]: {key: TEXT_KEY, value: []},
    [REMOVE_COMMENT_ACTION]: {key: COMMENT_KEY, value: []},
    [UPDATE_COMMENT_ACTION]: {key: TEXT_KEY, value: []},
    [SEND_VALORATION_ACTION]: {key: SCORE_KEY, value: []},
    [REMOVE_VALORATION_ACTION]: {key: '', value: []},
    [SET_FAVORITE_ACTION]: {key: '', value: []},
    [UNSET_FAVORITE_ACTION]: {key: '', value: []},
    [REPORT_CONTENT_ACTION]: {key: REASON_KEY, value: []},
}