import React, {useEffect, useState, useRef} from 'react';
import {Row, Col} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import { useKeycloak } from "@react-keycloak/web";
import './PerfilUsuario.css';
import {user, interops, signup, menu} from '@core'
import {useDispatch, useSelector} from "react-redux";
import avatarDefault from '@app/Resources/Images/avatardefault.png';
import iconTrophy from '@app/Resources/Images/icon_trophy.svg';

import {NavLink} from "react-router-dom";
import {URL_RANKING} from "@components/Routes/Location";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit} from '@fortawesome/free-solid-svg-icons'

import {
    displayOnBoardingActionCreator,
    togglePasswordModeActionCreator,
    turnOffLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";

import {arrayFormatter, compareUserProfileObjects} from "../../../../app/src/Utils/utils";
import {DATA_LOADING_STATUS_LOADED} from "../../../../app/src/Utils/Constants";
import SelectCategoriesComponent from "./PerfilComponents/SelectCategoriesComponent";
import useOutput from "../../../../app/src/Utils/Hooks/useOutput";
import AchievementComponent from "./RankingComponents/components/AchievementsComponent";
import PasswordModalComponent from "./PerfilComponents/PasswordModalComponent";
import AlertNotification from "../../../../app/src/Components/GUI/Alert/Alert";
import AvatarModalComponent from "./PerfilComponents/AvatarModalComponent";
import {
    PASSWORD_ERROR_FAILED_NEW_PASSWORD,
    PASSWORD_ERROR_INVALID_CREDENTIALS,
    PASSWORD_ERROR_INVALID_NEW_PASSWORD, PASSWORD_ERROR_SECURE_CREDENTIALS
} from "./PerfilUsuarioConstants";
import UserInfoComponent from "./PerfilComponents/UserInfoComponent";
import UserButtonOptionsComponent from "./PerfilComponents/UserButtonOptionsComponent";
import TermsModalComponent from "./PerfilComponents/TermsModalComponent";
import {MULTITENANT_CONSTANTS, TENANT} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {getFileService} from "../../../../app/src/services/services";

function PerfilUsuario(props) {
    const loading = useSelector(state => state.commonState.loading);
    const refPass = useRef(null);
    const refAvatar = useRef(null);
    const refTerms = useRef(null);
    const dispatch = useDispatch();
    const {userAppController, menuAppController} = props;

    const locale = localStorage.getItem("petsigo-locale");

    const outputFn = useOutput();
    const [controller, setController] = useState(null);
    const [userProfile, setUserProfile] = useState(null);

    const {t} = useTranslation();

    const [editUserInfo, setEditUserInfo] = useState(false);

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");

    const [passwordModal, setPasswordModal] = useState(false);
    const [wrongCurrentPassword, setWrongCurrentPassword] = useState(false);
    const [invalidNewPassword, setInvalidNewPassword] = useState(false);
    const [failedNewPassword, setFailedNewPassword] = useState(false);
    const [passwordsMustMatch, setPasswordsMustMatch] = useState(false);
    const [samePassword, setSamePassword] = useState(false);
    const [successEdit, setSuccessEdit] = useState(false);

    const invalidPasswordOptions = [wrongCurrentPassword, invalidNewPassword, failedNewPassword, passwordsMustMatch, samePassword];

    const [disabledPassEdit, setDisabledPassEdit] = useState(null);

    const [avatarModal, setAvatarModal] = useState(false);
    const [avatar, setAvatar] = useState(false);
    const [terms, setTerms] = useState(null);
    const [termsModal, setTermsModal] = useState(null);
    const [tutorialUrl, setTutorialUrl] = useState(null);

    let userCredentials;


    const {userData} = useSelector((state) => state.commonState);
    const { keycloak } = useKeycloak();

    const handleDisplayOnBoarding = (value) => {
        dispatch(displayOnBoardingActionCreator(value))
    }

    useEffect(() => {
        if (passwordModal) {
            setDisabledPassEdit(!currentPassword || !newPassword1 || !newPassword2 || invalidPasswordOptions.filter(value => value).length)

            if ((userProfile?.credentials && currentPassword !== userProfile?.credentials) || newPassword1 !== currentPassword) {
                setSamePassword(false);
            } else if ((userProfile?.credentials && currentPassword === userProfile?.credentials) || (newPassword1 && currentPassword && newPassword1 === currentPassword)) {
                setSamePassword(true);
            }

            if (newPassword1 && newPassword2 && newPassword1 === newPassword2) {
                setPasswordsMustMatch(false);
            } else if (newPassword1 && newPassword2 && newPassword1 !== newPassword2) {
                setPasswordsMustMatch(true);
            }
        }
    }, [currentPassword, newPassword1, newPassword2, invalidPasswordOptions, passwordModal]);

    useEffect(() => {
        if (!passwordModal) {
            resetPasswordState();
        }
    }, [passwordModal])

    useEffect(() => {
        if (userProfile !== null) {
            setFirstName(userProfile?.firstName);
            setLastName(userProfile?.lastName);
            setAvatar(userProfile?.avatar);
        }
    }, [userProfile])

    useEffect(() => {
        if (successEdit) {
            setTimeout(() => handleSetSuccessEdit(false), 2000);
        }
    }, [successEdit])


    const handleSetCurrentPassword = event => {
        setCurrentPassword(event.target.value);
    };

    const handleSetNewPassword1 = event => {
        setNewPassword1(event.target.value);
    };

    const handleSetNewPassword2 = event => {
        setNewPassword2(event.target.value);
    };

    const handleSetFirstName = event => {
        setFirstName(event.target.value);
    }

    const handleSetLastName = event => {
        setLastName(event.target.value);
    }

    const handleEditUserInfo = () => {
        setEditUserInfo(editUserInfo => !editUserInfo)
    }

    const handleSendUserData = () => {
        if (firstName !== userProfile.firstName || lastName !== userProfile.lastName) {
            controller.dispatch(new user.events.UpdateFirstName(firstName));
            controller.dispatch(new user.events.UpdateLastName(lastName));
            setEditUserInfo(false);
        }
    }

    const resetPasswordState = () => {
        setFailedNewPassword(false);
        setWrongCurrentPassword(false);
        setSamePassword(false);
        setPasswordsMustMatch(false);
        setInvalidNewPassword(false);
        setSuccessEdit(false);

        setCurrentPassword("");
        setNewPassword1("");
        setNewPassword2("");
    };

    const handleCheckNewPassword = () => {
        const ev = new user.events.CheckCredentialsSecurity(newPassword1);
        controller.dispatch(ev);
    };

    const handleSendUserCredentials = (current, new1) => {
        const ev = new user.events.UpdateCredentials(current, new1);
        controller.dispatch(ev);
    };

    const handleTermsModal = () => {
        setTermsModal(termsModal => !termsModal)
    };

    const handlePasswordModal = value => {
        togglePasswordMode();
        setPasswordModal(value);
    }

    const handleSetWrongCurrentPassword = () => {
        if (wrongCurrentPassword) {
            setWrongCurrentPassword(false);
        }
    }

    const handleAvatarModal = () => {
        setAvatarModal(avatarModal => !avatarModal);
    }

    const handleSetAvatar = value => {
        setAvatar(value)
    };

    const handleSetSuccessEdit = value => {
        setSuccessEdit(value);
    };

    const handleSendAvatar = () => {
        const ev = new user.events.UpdateAvatar(avatar);
        controller.dispatch(ev);
        handleAvatarModal();
    };

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    }

    const togglePasswordMode = () => {
        dispatch(togglePasswordModeActionCreator())
    }

    const menuModel = (o) => {
        if (o.tutorialUrl) {
            setTutorialUrl(o.tutorialUrl);
        }

        if (o.legalText) {
            setTerms(o.legalText);
        }

        if (controller && o.language && o.language !== locale) {
            controller.dispatch(menu.events.Reload);
        }
    };

    const menuOutput = () => {
    };

    useEffect(() => {
        /*const con = userAppController;
        let binding = con.bind((o, userCredentials) => model(o, userCredentials), o => outputFn(o, output, user));
        con.dispatch(user.events.LogUserProfileView)
        con.dispatch(user.events.GetAvailableAvatars);
        setController(con)*/

        const menuCon = menuAppController;
        let menuBinding = menuCon.bind(menuModel, o => outputFn(o, menuOutput, menu));

        return () => {
            /*con.unbind(binding);*/
            menuCon.unbind(menuBinding)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const signupModel = o => {
        if (o.legalConditions) {
            setTerms(o.legalConditions)
        }
    };

    const signupOutput = () => {
    };

    /*const model = (o) => {
        const formattedModel = {};

        formattedModel.username = o.username;
        formattedModel.firstName = o.firstName;
        formattedModel.lastName = o.lastName;
        formattedModel.email = o.email;
        formattedModel.ranking = o.ranking;
        formattedModel.avatar = o.avatar;
        formattedModel.loading = o.loadingUser.name$;

        if ((o?.credentials && !userCredentials) || (o?.credentials && userCredentials && o?.credentials !== userCredentials)) {
            handlePasswordModal(false);
            handleSetSuccessEdit(true);
            userCredentials = o?.credentials;
        }

        if (!o.avatar) {
            o.avatar = {
                url: avatarDefault
            }
            formattedModel.avatar = o.avatar;
        }

        if (o.availableAvatars) {
            const as = [];
            const it = o.availableAvatars.iterator();
            while (it.hasNext()) {
                const a = it.next();
                as.push(a)
            }
            formattedModel.availableAvatars = as;
        }

        if (o.interests) {
            formattedModel.interests = arrayFormatter(o.interests);
        }

        if (o.achievements) {
            formattedModel.achievements = arrayFormatter(o.achievements);
        }

        if (o.rewards) {
            formattedModel.rewards = arrayFormatter(o.rewards);
        }


        if (formattedModel.loading === DATA_LOADING_STATUS_LOADED && compareUserProfileObjects(userProfile, formattedModel)) {
            if (userProfile === null || loading) {
                turnOffLoading()
            }
            setUserProfile(formattedModel);
        }
    }*/

    const getFileCallback = (response) => {
        const formattedModel = {...setUserProfile, avatar: {url:response.data.data.url}}
        setUserProfile(formattedModel);
        turnOffLoading();
    }

    useEffect(() => {
        if (!!userData) {
            const userToken = keycloak?.token
            getFileService(TENANT, userData.avatar, userToken, getFileCallback);
            const formattedModel = setUserProfile;
            formattedModel.username = userData.username;
            formattedModel.firstName = userData.firstname;
            formattedModel.lastName = userData.lastname;
            formattedModel.email = userData.email;
            formattedModel.interests = userData.interests;
            formattedModel.ranking = "";
            formattedModel.loading = "";
            setUserProfile(formattedModel);
        }
    }, [userData]);

    /*const output = (o) => {

        if (interops.getKotlinName(o) === PASSWORD_ERROR_INVALID_CREDENTIALS) {
            setWrongCurrentPassword(true);
        }

        if (interops.getKotlinName(o) === PASSWORD_ERROR_INVALID_NEW_PASSWORD) {
            setInvalidNewPassword(true);
        }

        if (interops.getKotlinName(o) === PASSWORD_ERROR_SECURE_CREDENTIALS) {
            setInvalidNewPassword(false);
        }

        if (interops.getKotlinName(o) === PASSWORD_ERROR_FAILED_NEW_PASSWORD) {
            setFailedNewPassword(true);
        }
    }*/

    useEffect(() => {
        let signupCon = null;
        let signupBinding = null;
        if (!terms) {
            signupCon = new signup.controller;
            signupBinding = signupCon.bind(signupModel, o => outputFn(o, signupOutput, signup));
            signupCon.dispatch(signup.events.GetLegalConditions);
        }
        return () => {
            if (!!signupCon && !!signupBinding) {
                signupCon.unbind(signupBinding);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [terms]);

    const [checkoutURL, setCheckoutURL] = useState(null);
    const [userController, setUserController] = useState(null);

    useEffect(() => {
        const userCon = userAppController;
        let userBinding = userCon.bind(userModel, o => outputFn(o, userOutput, user));
        setUserController(userCon);
        return () => userCon.unbind(userBinding)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRedirectToMySubscriptions = () => {
        const successUrl = `${window.location.origin}/user`;
        userController?.dispatch(new user.events.GetProductsDashboard(successUrl));
    }

    useEffect(() => {
        handleRedirectToMySubscriptions()
    }, [userController]);

    const userOutput = () => {
    };

    const userModel = o => {
        if (o.loadingProductsDashboard.name$ === DATA_LOADING_STATUS_LOADED && o.latestProductsDashboard.url) {
            setCheckoutURL(o.latestProductsDashboard.url)
        }
    };

    return (
        <div className="Perfil">
            {userProfile !== null &&
            <div className="App-container">
                <Row className="Datos-usuario">
                    <Col md={12} lg={12} className={"UserBlock"}>
                        <Row style={{width: "100%"}}>
                            {userProfile?.avatar &&
                            <Col md={12} lg={2} className="UserAvatar">
                                <div className={"Avatar-imaxe-container"}>
                                    <img className={"Avatar-imaxe"} alt={"Avatar"}
                                         src={userProfile.avatar.url}
                                         onClick={handleAvatarModal}/>
                                </div>
                                {
                                    MULTITENANT_CONSTANTS[TENANT].gamification &&
                                    <div className="Avatar-points">
                                        <img className={"Icono-trophy"} alt={"Icon"}
                                             src={iconTrophy}/>
                                        <span>{userProfile?.ranking?.points}</span>
                                    </div>
                                }

                            </Col>}
                            <Col md={12} lg={10} className={"UserInfo"}>
                                <Row className={"UserInfoHeader"}>
                                    {userProfile?.username &&
                                    <h3 className={"Username"}>{userProfile.username}</h3>}

                                    {editUserInfo ?
                                        <div onClick={handleEditUserInfo} className={"EditionButton"}>
                                            <ion-icon name="close"/>
                                            <p>{t('perfil.edit.cancel')}</p>
                                        </div> :
                                        <FontAwesomeIcon className="Link-datos" icon={faEdit}
                                                         onClick={handleEditUserInfo}/>}

                                </Row>
                                <Row>
                                    <UserInfoComponent userProfile={userProfile} editUserInfo={editUserInfo}
                                                       firstName={firstName} handleSetFirstName={handleSetFirstName}
                                                       lastName={lastName} handleSetLastName={handleSetLastName}
                                                       handleSendUserData={handleSendUserData}/>
                                    <Col md={12} lg={6}>
                                        {MULTITENANT_CONSTANTS[TENANT].showUserInterests &&
                                        <SelectCategoriesComponent userProfile={userProfile} userAppController={userAppController}/>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Col>
                </Row>

                <Row>
                    <UserButtonOptionsComponent handlePasswordModal={handlePasswordModal}
                                                handleTermsModal={handleTermsModal}
                                                handleDisplayOnBoarding={handleDisplayOnBoarding}
                                                checkoutURL={checkoutURL}
                                                terms={terms}
                                                tutorialUrl={tutorialUrl}
                    />
                </Row>

                {
                    !!MULTITENANT_CONSTANTS[TENANT].gamification &&
                    <Row>
                        <Col md={12} lg={7} className={"RewardsContainer"}>
                            <Col className="Ranking-usuario">
                                <Row className={"RewardsHeader"}>
                                    <h2 className={"BlockTitle Title-bold"}>{t('perfil.insignias')}</h2>
                                    <NavLink to={{pathname: URL_RANKING}} className={"Link-ranking"}>
                                        <img className={"Icono-trophy"}
                                             src={iconTrophy}
                                             alt={'icon'}/>
                                    </NavLink>
                                </Row>
                                <Row className={"Row-medals"}>
                                    <AchievementComponent renderTitle={true}
                                                          achievements={userProfile?.achievements}
                                                          rewards={userProfile?.rewards}/>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                }
            </div>}
            {termsModal && <TermsModalComponent refTerms={refTerms} handleTermsModal={handleTermsModal} terms={terms}/>}
            {passwordModal &&
            <PasswordModalComponent handleSendUserCredentials={handleSendUserCredentials} refPass={refPass}
                                    handlePasswordModal={handlePasswordModal}
                                    invalidPasswordOptions={invalidPasswordOptions}
                                    currentPassword={currentPassword}
                                    handleSetCurrentPassword={handleSetCurrentPassword}
                                    newPassword1={newPassword1}
                                    handleSetNewPassword1={handleSetNewPassword1}
                                    newPassword2={newPassword2}
                                    handleSetNewPassword2={handleSetNewPassword2}
                                    handleCheckNewPassword={handleCheckNewPassword}
                                    handleSetWrongCurrentPassword={handleSetWrongCurrentPassword}
                                    disabledPassEdit={disabledPassEdit}/>}
            {avatarModal &&
            <AvatarModalComponent refAvatar={refAvatar} handleSendAvatar={handleSendAvatar}
                                  handleAvatarModal={handleAvatarModal}
                                  availableAvatars={userProfile?.availableAvatars} avatar={avatar}
                                  handleSetAvatar={handleSetAvatar}/>}
            {successEdit &&
            <AlertNotification type="success" showtitle={true} text={t('userupdate.password.success')}/>}
            {samePassword &&
            <AlertNotification type="error" showtitle={true} text={t('register.samePassword')}/>}
            {passwordsMustMatch &&
            <AlertNotification type="error" showtitle={true} text={t('register.invalidpassword2')}/>}
            {wrongCurrentPassword &&
            <AlertNotification type="error" showtitle={true} text={t('userupdate.wrongCurrentPassword')}/>}
            {invalidNewPassword &&
            <AlertNotification type="error" showtitle={true} text={t('userupdate.invalidNewPassword')}/>}
            {failedNewPassword &&
            <AlertNotification type="error" showtitle={true} text={t('userupdate.failedNewPassword')}/>}
        </div>
    );

}

export default PerfilUsuario;